.buttonGroup button {
    background: white;
    border: 1px solid #ced4da;
    padding: 0px;
    border-radius: .25rem;
    min-width: 75px;
    text-align: center;
    min-height: 32px;
}

.buttonGroup button[disabled] {
    background: #e9ecef;
}

.buttonGroup button:not(:first-child) {
    flex-grow: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.buttonGroup button:not(:last-child) {
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.buttonGroup button:hover:not(:focus):not(:disabled) {
    background: #c8dbfd;
    transition: 250ms;
}

.buttonGroup-button-selected {
    background: #1775D1 !important;
    color: white !important;
    border: 1px solid #1775D1 !important;
}

.buttonGroup-button-placeholder {
    color: #e9ecef;
}