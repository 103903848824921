.modelInput-section-row {
    padding: 0px 0px 5px 0px;
}

.modelInput-sectionHeader-row {
    text-align: left;
    margin-bottom: 0px;
}

.modelInput-sectionHeader-span {
    font-weight: 600;
    color: #323130;
}

.modelInputColumns-row {
    display: flex;
    align-self: flex-end;
    align-items: center !important;
    padding-left: 15px;
    margin-bottom: 5px;
}

.modelInput-label {
    font-size: 15px;
    font-weight: 500;
    color: #323130;
    display: flex;
    align-items: flex-end;
    text-align:left;
    margin-bottom: 2px;
}

.modelInput-input-col {
    justify-content: left;
    margin-bottom: 5px;
}

.modelInput-numInput-form {
    max-width: 150px;
    max-height: 32px;
    text-align: center;
}

.modelInput-numInput-form::placeholder {
    color:rgb(172, 172, 172);
    text-align:center;
}

.modelInput-option-dropdown {
    width: 8rem !important;
}

.modelInputPredict-button {
}

.modelInput-alert {
    padding: 0px;
    color: #323130;
    font-weight: 500;
}

.modelInput-result-span {
    font-size: 16px;
    font-weight: 500;
}

.modelInputPredict-button-col {
    margin-top: 5px;
}

.modelInputPredict-col {
    justify-content: right;
    text-align: right;
}

.modelInput-hr {
    margin: 0px 0px 15px 0px;
}