/**************************** Basic Card CSS *******************************/
.card {
    border-radius: 12px;
    margin-top: 15px;
    border: 1px solid rgba(140,160,179,.35);
    box-shadow: 0px 1px 5px rgba(73,135,251,.25);
    animation: slide-in 500ms ease;
    transition: 300ms;
    flex-grow: 1;
    max-width: 900px;
    min-width: 900px;
}

.card:disabled {
    opacity: 0.7;
    pointer-events: none;
}

.card[disabled] {
    opacity: 0.7;
    pointer-events: none;
}