.outlinedButton {
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    width: fit-content;
    min-width: 85px;
    border-radius: 8px;
    background: white;
    border: 1px solid #024A9F;
    color: #024A9F;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    text-align: center;
    padding: 8px 12px;
}
  
.outlinedButton:hover {
    background-color: #024A9F;
    border-color: #024A9F;
    color: white;
    outline: none;
}
  
.outlinedButton:focus {
    outline: none;
    background: white;
    color: #024A9F;
    border: 1px solid #024A9F;
}
  
.outlinedButton:disabled {
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    border: 1px solid #CCCCCC;
    background: transparent;
    color: #CCCCCC;
    outline: none;
}
  
.outlinedButton:disabled:hover {
    transform: scale(1.00);
}