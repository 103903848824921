.homeLogo-div {
    min-width: 900px;
    max-width: 900px;
    display: flex;
    justify-content: flex-start;
}

.home-container-div {
    background-color: white;
    background-image: url('../media/Doctors-pana\ 1.svg'), url('../media/Group 318.svg');    
    background-position: left bottom, right bottom;
    background-repeat: no-repeat, no-repeat;
    justify-content: center;
    display: flex;
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    padding-top: 25px;
    min-height: 100vh;
    max-height: 100vh;
}

.card { 
    border-radius: 100px;
}

.home-card {
    z-index: 1;
    animation: 500ms slide-up ease;
    padding: 15px 20px;
    margin-bottom: 30px;
    min-width: 900px;
    max-width: 900px;
}

.home-cardBody {
    padding: 0px 15px !important;
    display: flex;
    flex-direction: column;
}

.home-cardTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-weight: 800 !important;
    font-size: 30px;
    color: #323130;
}

.home-badge {
    font-size: 13px;
    margin-left: 10px;
    background-color: #024A9F;
}

.modelInput-description {
    text-align: start;
    margin-bottom: 10px;
    font-size: 15px;
}