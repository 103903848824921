.accordion-button {
    background: transparent;
}

.accordion-button.collapsed {
    padding: 10px 15px;
    font-weight: 500;
}

.accordion-button:not(.collapsed) {
    padding: 10px 15px;
    font-weight: 500;
}

.accordion-body {
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    text-align: start;
}

.sources-ul {
    margin-bottom: 0px;
}